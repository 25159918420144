export function read_more() {
    const read_more = document.querySelectorAll(".--read-more");

    Array.from(read_more).forEach((element) => {
        let text = element.querySelector('.--text');
        let btn = element.querySelector('.--clickable');

        let text_temp = text.innerHTML;

        let text_length = text.innerHTML.split(" ");
        let text_shortened = text_length.slice(0, 250).join(" ");
        text.innerHTML = text_shortened;

        if (text_shortened.length === text_temp.length) {
            btn.style.display = "none";
        }

        btn.addEventListener('click', (event) => {
            event.preventDefault();

            if (btn.innerHTML === "Læs mere") {
                btn.innerHTML = "Læs mindre";
                text.innerHTML = text_temp;
            } else {
                btn.innerHTML = "Læs mere";
                text.innerHTML = text_shortened;
            }
        }, false);
    });
}