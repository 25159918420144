import domReady from '@roots/sage/client/dom-ready';
import { read_more } from './read-more.js';

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  /* This is for removing HTML comments in the DOM, as to not help hackers or
  others with malicious intent to understand the site structure. */
  $('*').contents().each(function() {
    if(this.nodeType === Node.COMMENT_NODE) {
      $(this).remove();
    }
  });

  read_more();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
